import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mapSeeder, strToMap } from "../../components/game/mapSeeder/mapSeeder";
import useCronometer from "../../components/game/useCronometer";
import gameActions from "../../redux/actions/gameActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import "./Game.css";

const ui = {
  menu: { show: false },
  header: { show: false },
  score: { show: true },
  timer: { show: false },
  cards: {},
  level: { show: false },
};

const levels = [
  { rows: 2, cols: 2 },
  { rows: 3, cols: 2 },
  { rows: 4, cols: 3 },
  { rows: 4, cols: 4 },
  { rows: 5, cols: 4 },
  // { rows: 6, cols: 5 },
  // { rows: 6, cols: 6 },
];

function useGame() {
  const { score, cards, map, level, cardsDiscovered, end } = useSelector(
    (store) => store.game
  );
  const dispatch = useDispatch();
  const endMap = strToMap("Thanks for playing!");
  const cronometer = useCronometer();
  const history = useHistory();

  const notShowCardsAgainAfterReloadPage = () => {
    map &&
      map.matrix &&
      !map.matrix[0][0].showed &&
      dispatch(gameActions.showCards());
  };

  const generateAndAddNewMap = (map) => {
    if (map && map.matrix) return;
    if (level === levels.length) {
      dispatch(gameActions.endGame());
    } else if (map && !map.matrix) {
      const { rows, cols } = levels[level];
      const newMap = mapSeeder(rows, cols);
      dispatch(gameActions.addMap(newMap));
    }
  };

  const removeSelectedCards = () => dispatch(gameActions.removeCards());

  const newGame = (map) => {
    removeSelectedCards();
    generateAndAddNewMap(map);
  };

  /* Start */
  useEffect(() => {
    notShowCardsAgainAfterReloadPage();

    return () => {
      dispatch(gameActions.newGame());
    };
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  useEffect(() => newGame(map), [map]);

  /* Next Level */
  useEffect(() => {
    if (cardsDiscovered === map.rows * map.cols) {
      dispatch(gameActions.nextLevel());
      dispatch(gameActions.removeMap());
    }
    // eslint-disable-next-line
  }, [cardsDiscovered]);

  /* Game Logic */
  useEffect(() => {
    if (end) return;
    if (cards.length >= 2) {
      if (cards[0].name === cards[1].name) {
        cards.map((card) => dispatch(gameActions.cardDiscovered(card)));
        dispatch(gameActions.sumScore());
        dispatch(gameActions.addCardDiscovered(2));
        dispatch(gameActions.cardsReveal(true));
        setTimeout(() => {
          dispatch(gameActions.cardsReveal(false));
        }, 100);
      } else {
        cards.map((card) =>
          dispatch(gameActions.cardReveal({ ...card, reveal: false }))
        );
        dispatch(gameActions.subScore());
      }
      dispatch(gameActions.removeCards());
    }
    // eslint-disable-next-line
  }, [cards]);

  const handleNewGame = () => {
    dispatch(gameActions.newGame());
  };

  // Handle lvl changes
  useEffect(() => {
    if (level >= 0) ui.score.show = true;
    if (level > 0) ui.timer.show = true;
    if (level > 1) ui.level.show = true;
    // if (level > 2) ui.menu.show = true;
    // if (level > 3) ui.header.show = true;
  }, [level]);

  useEffect(() => {
    const win = level === levels.length;
    if (win) history.push("/end/win");
    else if (end) history.push("/end/lose");
  }, [end, level]);

  return {
    ui,
    levels,
    level,
    score,
    map,
    endMap,
    cronometer,
    handleNewGame,
  };
}

export default useGame;
