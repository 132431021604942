function Score({ show, score }) {
  if (!show) return <></>;
  return (
    <div className="d-flex justify-content-center text-warning mt-4">
      <div className="score fs-3">
        <i className="fas fa-star me-2"></i>
        <span>{score}</span>
      </div>
    </div>
  );
}

export default Score;
