/* Tiles */
const landTile = (letterNum) => ({
  name: String.fromCharCode(letterNum),
  icon: getIcon(letterNum),
});

/* Map Seeder */
export function mapSeeder(rows, cols) {
  const firstLetterNum = "A".charCodeAt();
  const lastLetterNum = "Z".charCodeAt();
  const res = [];
  let letters = [];

  if ((rows * cols) / 2 > lastLetterNum - firstLetterNum) return [];

  for (let i = 0; i < (rows * cols) / 2; i++) {
    letters.push(landTile(firstLetterNum + i));
  }

  letters = [...letters, ...letters];
  letters = messArray(letters);

  let k = 0;
  for (let i = 0; i < rows; i++) {
    res.push([]);
    for (let j = 0; j < cols; j++) {
      res[i].push({ ...letters[k], row: i, col: j });
      k++;
    }
  }
  return { matrix: res, rows, cols };
}

function messArray(array) {
  const res = [];
  const aux = [...array];

  while (aux.length) {
    const randomNum = Math.floor(Math.random() * aux.length);
    const itemRemoved = aux.splice(randomNum, 1)[0];
    res.push(itemRemoved);
  }

  return res;
}

export const strToMap = (str) => {
  const res = str.split("");
  return {
    matrix: [
      res.map((letter) => ({
        name: letter,
        reveal: true,
      })),
    ],
  };
};

function getIcon(letterNum) {
  switch (letterNum) {
    case 65:
      return "🐶";
    case 66:
      return "🐱";
    case 67:
      return "🐭";
    case 68:
      return "🐹";
    case 69:
      return "🐰";
    case 70:
      return "🦊";
    case 71:
      return "🐻";
    case 72:
      return "🐼";
    case 73:
      return "🐨";
    case 74:
      return "🐯";
    case 75:
      return "🦁";
    case 76:
      return "🐮";
    case 77:
      return "🐷";
    case 78:
      return "🐽";
    case 79:
      return "🐸";
    case 80:
      return "🐵";
    case 81:
      return "🙈";
    case 82:
      return "🙉";
    case 83:
      return "🙊";
    case 84:
      return "🐒";
    case 85:
      return "🐔";
    case 86:
      return "🐧";
    case 87:
      return "🐦";
    case 88:
      return "🐤";
    case 89:
      return "🐣";
    case 90:
      return "🐥";
    default:
      return "";
  }
}
