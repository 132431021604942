import { Link } from "react-router-dom/cjs/react-router-dom.min";
import LevelMap from "../Game/components/LevelMap";
import React from "react";

function Logo() {
  return (
    <img
      src="./imgs/moon.png"
      alt="logo"
      className="logo mb-4 rounded-circle spin-500"
      style={{ width: "12rem" }}
    />
  );
}

function Title() {
  return <h1 className="text-center fs-1 c-text-primary">Memorice</h1>;
}

function BtnPlay() {
  return (
    <Link className="my-4 c-btn c-px-10" to={"/play"}>
      <i className="fas fa-play me-2"></i>
      Play
    </Link>
  );
}

function Card() {
  return (
    <div
      style={{
        backgroundImage: `url(./imgs/c1.png)`,
        backgroundSize: "cover",
        repeat: "no-repeat",
      }}
      className="c-card"
    ></div>
  );
}

function Divider({ i }) {
  const isLg = window.innerWidth >= 992;
  if (!isLg) return <></>;

  const cardsEvery = 9;
  const addDiv = (i) => (i + 1) % cardsEvery === 0;
  return <>{addDiv(i) && <div className="w-100"></div>}</>;
}

export default function MainMenu() {
  return (
    <div className="min-vw-100 min-vh-100 d-flex flex-column justify-content-center align-items-center p-5">
      {/* Testing */}
      {/* <LevelMap show={true} levels={[1, 2, 3, 4, 5]} level={1} /> */}
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Logo />
        {/* <Title /> */}
      </div>
      <BtnPlay />
      <div className="d-flex flex-wrap justify-content-center mt-2">
        {Array.from({ length: 18 }).map((_, i) => (
          <React.Fragment key={i}>
            <Card />
            <Divider i={i} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
