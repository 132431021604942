import "bootstrap/dist/css/bootstrap.min.css";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import "./App.css";
import Lose from "./views/End/Lose/Lose";
import Win from "./views/End/Win/WIn";
import Game from "./views/Game/Game";
import MainMenu from "./views/MainMenu/MainMenu";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import actions from "./redux/actions/gameActions";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname !== "/") window.location.href = "/";
    dispatch(actions.newGame());
  }, []);

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={MainMenu} />
          <Route exact path="/play" component={Game} />
          <Route exact path="/end/win" component={Win} />
          <Route exact path="/end/lose" component={Lose} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
