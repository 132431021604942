function Circle({ children, size = "2rem" }) {
  return (
    <div
      style={{ height: size, width: size }}
      className={`rounded-circle outline border-dark border text-muted d-flex justify-content-center align-items-center`}
    >
      <span className="pb-1">{children}</span>
    </div>
  );
}

function Line({ show }) {
  if (!show) return <></>;
  return (
    <span
      style={{ width: "2rem" }}
      className={`border-bottom border-dark`}
    ></span>
  );
}

function Node({ index, lvl, level, levels }) {
  const text = index + 1;
  const sizeCircle = level === lvl ? "3rem" : "2rem";
  const showLine = index < levels.length - 1;

  return (
    <>
      <Circle size={sizeCircle}>{text}</Circle>
      <Line show={showLine} />
    </>
  );
}

function LevelMap({ levels, level, show }) {
  if (!show) return <></>;
  return (
    <div
      style={{ minHeight: "20vh", minWidth: "50vw" }}
      className="mx-auto text-center p-2 rounded"
    >
      <div className="d-flex flex-row my-4 justify-content-center align-items-center flex-wrap">
        {/* <i class="fas fa-flag me-4 text-secondary"></i> */}
        {levels
          .filter((lvl) => lvl > 0)
          .map((lvl, index) => (
            <Node
              key={index}
              index={index}
              lvl={lvl}
              level={level}
              levels={levels}
            />
          ))}
      </div>
    </div>
  );
}

export default LevelMap;
