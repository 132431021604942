import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import gameActions from "../../redux/actions/gameActions";

function useCronometer() {
  const dispatch = useDispatch();
  const { level, score } = useSelector((store) => store.game);

  const maxTime = 100;
  const [time, setTime] = useState(maxTime);

  const intervalId = useRef(null);
  function handleProgressTimeout() {
    let id = setInterval(() => setTime((prev) => prev - 1), 100);
    intervalId.current = id;
  }

  function getProgressTimeoutBarWidth(time) {
    const width = (time / maxTime) * 100;
    return width >= 0 ? width : 0;
  }

  useEffect(() => {
    if (level > 0) {
      setTime(maxTime);
      handleProgressTimeout();
    } else {
      setTime(maxTime);
      if (intervalId.current) clearInterval(intervalId.current);
    }

    return () => {
      if (intervalId.current) clearInterval(intervalId.current);
    };
    // eslint-disable-next-line
  }, [level]);

  // if score up then up time
  const prevScore = useRef(score);
  useEffect(() => {
    if (score < prevScore.current) return;

    setTime((prev) => {
      let newTime = prev + 10;
      if (level > 4) newTime = prev + 10;
      if (level > 5) newTime = prev + 10;
      return newTime <= maxTime ? newTime : maxTime;
    });
    prevScore.current = score;
    // eslint-disable-next-line
  }, [score]);

  useEffect(() => {
    if (time === 0) dispatch(gameActions.endGame());
    // eslint-disable-next-line
  }, [time]);

  return { time, getProgressTimeoutBarWidth };
}

export default useCronometer;
