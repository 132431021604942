import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import gameActions from "../../redux/actions/gameActions";
import "./Tile.css";

function Tile({ tile }) {
  const game = useSelector((state) => state.game);
  const [reveal, setReveal] = useState(undefined);
  const [discovered, setDiscovered] = useState(undefined);
  const dispatch = useDispatch();

  const handleReveal = () => {
    if (!discovered && !reveal) {
      setReveal(true);
      dispatch(gameActions.addCard({ ...tile }));
    }
  };

  useEffect(() => {
    setDiscovered(tile.discovered);
    if (reveal === undefined && !tile.reveal);
    else setReveal(tile.reveal);
    // eslint-disable-next-line
  }, [tile]);

  return (
    <button
      className={`tile border-crimson danger text-center d-flex align-items-center justify-content-center outline rounded
       ${discovered ? "discovered" : ""} ${
        game.end ? "border-darkt1" : ""
      } fs-3`}
      onClick={handleReveal}
      aria-label={`Card Row ${tile.row} Col ${tile.col}`}
    >
      {/* {reveal && <span className={reveal ? "show" : "hide"}>{tile.name}</span>} */}
      <span
        style={{ color: tile.color ? tile.color : "" }}
        className={`${
          game.end || reveal
            ? "show"
            : reveal === undefined
            ? tile.showed
              ? "invisible"
              : "hide2"
            : "hide"
        } `}
      >
        {tile.icon || tile.name}
      </span>
    </button>
  );
}

export default Tile;
